import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './SnakeDraft.scss';
import ZoomableCard from '../Decks/ZoomableCard';
import { sendSocketMessage } from '../../redux/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormCheck, FormGroup } from 'react-bootstrap';

const SnakeDraft = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.account.user);
    const currentGame = useSelector((state) => state.lobby.currentGame);

    const draftState = currentGame.draftState;
    const myChoices = draftState?.playerChoices[user.username];
    const myDiscard = draftState?.playerDiscards[user.username];
    const playerNames = Object.values(currentGame.players).map(p => p.name);
    const otherPlayerName = playerNames.find(n => n !== user.username);
    const theirChoices = draftState?.playerChoices[otherPlayerName];
    const headerAdvice = draftState?.complete ? 'Complete!' : myTurn ? 'Your Choice' : 'Their Choice';
    const discardAdvice = draftState?.complete ? 'Click to discard one' : '';
    const myTurn = draftState?.choosingPlayer === user.username && !draftState?.complete;
    const onChampClick = (champId) => {
        if (!myChoices || myChoices.length < 4) {
            dispatch(sendSocketMessage('draftChamp', currentGame.id, champId));
        }
    }
    const isChampTaken = (id) => {
        return theirChoices?.includes(id) || myChoices?.includes(id);
    }

    const onChampDiscard = (champId) => {
        if (myChoices?.length === 4) {
            dispatch(sendSocketMessage('discardChamp', currentGame.id, champId));
        }
    }

    return (
        <div className='lobby-card'>
            <div className='lobby-header'>Pod Draft - {headerAdvice}</div>
            <div className='champ-set'>
                <div className='champ-group'>
                    <div className='snake-controls'>
                        <div>Your Picks</div>
                        {myChoices?.length === 4 && <FontAwesomeIcon icon={faCheck} className='complete' />}
                    </div>
                    {myChoices && myChoices.filter(mc => mc !== myDiscard).map(c =>
                        <div key={c} className={`game-card large vertical champ`}>
                            <ZoomableCard card={{ imageStub: c }} noIndex={true}
                                onClick={() => onChampDiscard(c)}
                            />
                        </div>
                    )}
                </div>
                <div className='champ-set'>
                    <div>{discardAdvice}</div>
                    {myDiscard && (
                        <div className={`game-card large vertical champ`}>
                            <ZoomableCard card={{ imageStub: myDiscard }} noIndex={true}
                            />
                        </div>
                    )}
                </div>
                <hr />

                <div className='champ-group'>
                    <div className='snake-controls'>
                        <div>Opponent's Picks</div>
                        {theirChoices?.length === 4 && <FontAwesomeIcon icon={faCheck} className='complete' />}
                    </div>
                    {theirChoices && theirChoices.map(c =>
                        <div key={c} className={`game-card large vertical champ`}>
                            <ZoomableCard card={{ imageStub: c }} noIndex={true} />
                        </div>
                    )}

                </div>

            </div>
            <hr />
            <div className='champ-set'>
                {draftState?.champSet.filter(c => !isChampTaken(c.id))
                    .map(ch => {
                        return (
                            <div key={ch.id} className='champ-group'>
                                <div className='snake-controls'><div>{ch.name}</div>
                                    {myTurn &&
                                        <button className='btn btn-primary def'
                                            onClick={() => onChampClick(ch.id)}
                                        >Choose</button>
                                    }
                                </div>
                                <div className={`game-card large vertical champ`}>
                                    <ZoomableCard card={{ imageStub: ch.id }} noIndex={true} />
                                </div>
                                {ch.cards.map(c => (
                                    <div key={c.id} className={`game-card normal  vertical`}>
                                        <ZoomableCard card={{ imageStub: c.id }} noIndex={true} />
                                    </div>
                                ))
                                }
                            </div>
                        )
                    })}
            </div>
        </div>
    )

}

SnakeDraft.displayName = 'SnakeDraft';

export default SnakeDraft;
