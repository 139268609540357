import React from 'react';
import { Modal } from 'react-bootstrap';
import GameConfiguration from './GameConfiguration';
import './GameConfigurationModal.scss';

const GameConfigurationModal = ({ optionSettings, onClose, onOptionSettingToggle }) => {

    return (
        <>
            <Modal show={true} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Game Configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GameConfiguration
                        optionSettings={optionSettings}
                        onOptionSettingToggle={onOptionSettingToggle}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

GameConfigurationModal.displayName = 'GameConfigurationModal';

export default GameConfigurationModal;
