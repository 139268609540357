import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import DeckList from '../Components/Decks/DeckList';
import ViewDeck from '../Components/Decks/ViewDeck';
import ApiStatus from '../Components/Site/ApiStatus';
import { Decks } from '../redux/types';
import { clearApiStatus, loadDecks, selectDeck } from '../redux/actions';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import debounce from 'lodash.debounce';
import './Decks.scss';

const DecksComponent = ({ onDeckSelected }) => {
    const dispatch = useDispatch();
    const apiState = useSelector((state) => {
        const retState = state.api[Decks.DeleteDeck];

        if (retState && retState.success) {
            retState.message = 'Deck deleted successfully';

            setTimeout(() => {
                dispatch(clearApiStatus(Decks.DeleteDeck));
            }, 1000);
        }

        return retState;
    });

    const [pbFilter, setPbFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [showFaves, setShowFaves] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const { numDecks, selectedDeck, deckReload } = useSelector((state) => ({
        numDecks: state.cards.numDecks,
        selectedDeck: standaloneDecks ? null : state.cards.selectedDeck,
        deckReload: state.cards.deckReload
    }));

    useEffect(() => {
        const filter = [
            { name: 'pb', value: pbFilter },
            { name: 'name', value: nameFilter },
            { name: 'favourite', value: showFaves }
        ];
        const pagingDetails = {
            pageSize: 8,
            page: pageNumber,
            sort: 'lastUpdated',
            sortDir: 'desc',
            filter: filter
        };

        dispatch(loadDecks(pagingDetails));
    }, [nameFilter, pbFilter, showFaves, dispatch, deckReload, standaloneDecks, pageNumber]);

    let onNameChange = debounce((event) => {
        event.preventDefault();
        event.stopPropagation();
        setNameFilter(event.target.value);
    }, 500);

    let onPbChange = (event) => {
        setPbFilter(event.target.value);
    };

    const handleFaveChange = () => {
        setShowFaves(!showFaves);
    };
    const onPageClick = (page) => {
        setPageNumber(page);
    };

    const {
        myDecks,
        standaloneDecks
    } = useSelector((state) => ({
        myDecks: state.cards.decks,
        standaloneDecks: state.cards.standaloneDecks
    }));

    const onTabChange = (index, lastIndex, event) => {
        let deck = null;
        switch (index) {
            case 0:
                deck = myDecks[0];
                break;
            case 1:
                deck = standaloneDecks[0];
                break;
        }
        dispatch(selectDeck(deck));
    };

    return (
        <div className='full-height'>
            <div className='lobby-card'>
                <Col sm={12}>
                    <ApiStatus
                        state={apiState}
                        onClose={() => dispatch(clearApiStatus(Decks.DeleteDeck))}
                    />
                </Col>

                <Tabs onSelect={onTabChange} >
                    <TabList>
                        <Tab>Pod Precons</Tab>
                    </TabList>
                    <TabPanel>
                        <Row>

                            <Col lg={6}>
                                <DeckList decks={standaloneDecks} />
                            </Col>
                            <Col lg={6}>{selectedDeck && <ViewDeck deck={selectedDeck} />}</Col>
                        </Row>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
};

DecksComponent.displayName = 'Decks';

export default DecksComponent;
