import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import 'jquery-migrate';
import { useDrag } from 'react-dnd';

import CardMenu from './CardMenu';
import CardCounters from './CardCounters';
import CardImage from './CardImage';
import { ItemTypes } from '../../constants';
import SquishableCardPanel from './SquishableCardPanel';
import StatusCondition from './StatusCondition';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Card.scss';

const Card = ({
    canDrag,
    card,
    className,
    disableMouseOver,
    onClick,
    onMenuItemClick,
    onMouseOut,
    onMouseOver,
    orientation = 'vertical',
    side,
    size,
    source,
    style,
    wrapped = true
}) => {
    const sizeClass = {
        [size]: size !== 'normal'
    };
    const [showMenu, setShowMenu] = useState(false);
    const manualMode = useSelector((state) => state.lobby.currentGame.manualMode);

    const [{ dragOffset, isDragging }, drag, preview] = useDrag({
        item: { card: card, source: source, type: ItemTypes.CARD },
        canDrag: () => canDrag || (!card.unselectable && card.canPlay),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            dragOffset: monitor.getSourceClientOffset()
        })
    });

    const isAllowedMenuSource = () => {
        return source === 'play area';
    };

    const onCardClicked = (event, card) => {
        event.preventDefault();
        event.stopPropagation();

        onClick && onClick(card, source);
    };

    const onMenuIconClicked = (event, card) => {
        event.preventDefault();
        event.stopPropagation();
        if (isAllowedMenuSource() && card.menu && card.menu.length !== 0) {
            setShowMenu(!showMenu);
            return;
        }

        onClick && onClick(card);
    };
    const getCountersForCard = (card) => {
        let counters = [];
        if (card.acquiredEffects?.length) {
            card.acquiredEffects.forEach((e) => {
                counters.push({
                    icon: e.effect,
                    name: e.name,
                    count: 1,
                    // fade: needsFade,
                    showValue: false
                });
            });
        }
        if (card.armor > 0) {
            counters.push({
                name: 'armor',
                count: card.armor,
                // fade: needsFade,
                showValue: true
            });
        }

        if (card.guarded) {
            counters.push({
                name: 'guarded',
                count: 1,
                // fade: needsFade,
                showValue: false
            });
        }

        for (const [key, token] of Object.entries(card.tokens || {})) {
            if (key !== 'exhaustion') {
                counters.push({
                    name: key,
                    count: token,
                    // fade: needsFade,
                    showValue: true
                });
            }
        }

        // for (const upgrade of card.upgrades || []) {
        //     counters = counters.concat(getCountersForCard(upgrade));
        // }

        return counters.filter((counter) => counter.count > 0);
    };

    const getCardSizeMultiplier = () => {
        switch (size) {
            case 'small':
                return 0.6;
            case 'large':
                return 1.4;
            case 'x-large':
                return 2;
        }

        return 1;
    };

    const getStatusConditions = () => {
        if (!['play area'].includes(source)) {
            return null;
        }

        if (['play area'].includes(source) && !card.statusConditions) {
            return null;
        }

        return (
            // <div className='conditions-wrapper'>
            // {
            card.statusConditions.map((s, index) => {
                return (
                    <StatusCondition
                        key={s.uuid}
                        status={s}
                        className={classNames('status', `status-${index}`)}
                        innerClassName={classNames('', {
                            selected: s.selected
                        })}
                        onClick={onCardClicked}
                        size={size}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    />);
            })
            // }
            // </div>
        );
    };

    const renderUnderneathCards = () => {
        // TODO: Right now it is assumed that all cards in the childCards array
        // are being placed underneath the current card. In the future there may
        // be other types of cards in this array and it should be filtered.
        let underneathCards = card.childCards;
        if (card.type === 'Champion') {
            underneathCards = underneathCards.concat(card.upgrades);
        }
        if (!underneathCards || underneathCards.length === 0) {
            return;
        }

        let maxCards = 2; //1 + (underneathCards.length - 1) / 6;
        return (
            <SquishableCardPanel
                cardSize={size}
                cards={underneathCards}
                // className='underneath'
                maxCards={maxCards}
                onCardClick={onClick}
                onMouseOut={onMouseOut}
                onMouseOver={onMouseOver}
                // source='underneath'
                reverse={true}
            />
        );
    };

    const getCardOrdering = () => {
        if (!card.order) {
            return null;
        }

        return <div className='card-ordering'>{card.order}</div>;
    };

    const shouldShowMenu = () => {
        if (!isAllowedMenuSource()) {
            return false;
        }

        if (!card.menu || !showMenu) {
            return false;
        }

        return true;
    };

    const showCounters = () => {
        if (['full deck'].includes(source)) {
            return true;
        }

        if (card.facedown) {
            return false;
        }

        return true;
    };

    const isFacedown = () => {
        return card.facedown || !card.name;
    };

    const getDragFrame = (image) => {
        if (!isDragging) {
            return null;
        }

        let style = {};
        if (dragOffset && isDragging) {
            let x = dragOffset.x;
            let y = dragOffset.y;
            style = {
                left: x,
                top: y
            };
        }

        return (
            <div className='drag-preview' style={style} ref={preview}>
                {image}
            </div>
        );
    };

    const showFlags = (card) => {
        return !card.facedown && !['Action', 'Reaction'].includes(card.type);
    };

    const getFlags = (card) => {
        return (
            <div className='flags'>
                <div className={`darkbg flag attack`}>
                    <span className='sr-only'>attack</span>
                    {card.attack}
                </div>
                <div className={`darkbg flag defense`}>
                    <span className='sr-only'>defense</span>
                    {card.defense}
                </div>
            </div>
        );
    };

    const getMenuBox = () => {
        if (manualMode && isAllowedMenuSource() && card.menu && card.menu.length !== 0) {
            return (
                <div className='card-menu-icon' onClick={(event) => onMenuIconClicked(event, card)}>
                    <FontAwesomeIcon icon={faBars} title='Show menu' />
                </div>
            );
        }
        return '';
    };

    const getCard = () => {
        if (!card) {
            return <div />;
        }

        let statusClass = getStatusClass();
        const combatClass = card.isAttacker || card.isDefender ? 'combat-' + side : null;

        let cardClass = classNames(
            'game-card',
            `card-type-${card.type}`,
            className,
            sizeClass,
            statusClass,
            combatClass,
            {
                'custom-card': card.code && card.code.startsWith('custom'),
                horizontal: orientation !== 'vertical',
                vertical: orientation === 'vertical',
                'can-play':
                    statusClass !== 'selected' &&
                    statusClass !== 'selectable' &&
                    !card.unselectable &&
                    !card.isAttacker &&
                    !card.isDefender &&
                    card.canPlay,
                unselectable: !card.selected && card.unselectable,
                dragging: isDragging,
                controlled: card.controlled,
                attacker: card.isAttacker,
                defender: card.isDefender,
                exhausted: card.exhausted
            }
        );
        let imageClass = classNames('card-image vertical', sizeClass, {
            exhausted: orientation === 'exhausted' || orientation === 'horizontal'
        });
        const image = card ? (
            <div className={imageClass}>
                <CardImage card={card} />
            </div>
        ) : null;

        const mouseOverAllowed = !disableMouseOver
            && (!isFacedown() || !card.parent) && onMouseOver;
        return (
            <div className='card-frame' ref={drag}>
                {getDragFrame(image)}
                {getCardOrdering()}
                <div
                    tabIndex={0}
                    className={cardClass}
                    onMouseOver={mouseOverAllowed ? () => onMouseOver(card) : undefined}
                    onMouseOut={!disableMouseOver && !isFacedown() ? onMouseOut : undefined}
                    onClick={(event) => onCardClicked(event, card)}
                >
                    {getMenuBox(card)}

                    <div>
                        <span className='card-name'>{card.name}</span>
                        {image}
                    </div>
                    {showCounters() && <CardCounters counters={getCountersForCard(card)} />}
                    {showFlags(card) && getFlags(card)}
                </div>
                {shouldShowMenu() && (
                    <CardMenu
                        cardName={card.name}
                        menu={card.menu}
                        side={side}
                        onMenuItemClick={(menuItem) => {
                            onMenuItemClick && onMenuItemClick(card, menuItem);
                            setShowMenu(!showMenu);
                        }}
                        onCloseClick={() => setShowMenu(false)}
                    />
                )}
            </div>
        );
    };

    const getStatusClass = () => {
        if (!card) {
            return undefined;
        }

        // location prevents highlighting cards we're about to meditate
        if (card.selected && card.location !== 'deck') {
            return 'selected';
        } else if (card.selectable) {
            // if (card.isAttacker) return 'attacker-' + side + ' selectable ';
            return 'selectable';
            // } else if (card.isAttacker) {
            //     return 'attacker-' + side;
            // } else if (card.isDefender) {
            //     return 'defender-' + side;
        } else if (card.new) {
            return 'new';
        }

        return undefined;
    };

    let styleCopy = Object.assign({}, style);
    if (card.upgrades) {
        styleCopy.top = card.upgrades.length * (15 * getCardSizeMultiplier());
    }
    if (wrapped) {
        return (
            <div className={classNames('card-wrapper', sizeClass, side)} style={style}>
                <div className='inner-wrapper'>
                    {getCard()}
                    {getStatusConditions()}
                </div>
                {renderUnderneathCards()}
            </div>
        );
    }

    return getCard();
};

Card.displayName = 'Card';

export default Card;
